import React from "react";

const Username = (props) => {
  return (
    <div className="input-group mb-3">
      <input
        onChange={(event) => props.setUsername(event.target.value)}
        type="text"
        className="form-control"
        placeholder="E-mail"
        aria-label="E-mail"
      />
    </div>
  );
};

export default Username;
