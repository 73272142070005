import React from "react";

const PasswordForm = (props) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <p>Set your password (6 characters minimum).</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              {/* <span className="input-group-text" id="basic-addon1">
                Password
              </span> */}
            </div>
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              aria-label="Password1"
              aria-describedby="basic-addon1"
              onChange={(event) => props.setpassword1(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              {/* <span className="input-group-text" id="basic-addon1">
                Confirm
              </span> */}
            </div>
            <input
              type="password"
              className="form-control"
              placeholder="Confirm Password"
              aria-label="Password2"
              aria-describedby="basic-addon1"
              onChange={(event) => props.setpassword2(event.target.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordForm;
