import CryptoJS from "crypto-js";

// For now using this to encrypt localStorage data to athe possiblity of users chaning userdata to see shipmens they otherwise should not see.

const salt = "the sea and pretzels are salty";

const Crypto = {
  encrypt(data) {
    return CryptoJS.AES.encrypt(data, salt);
  },

  decrypt(data) {
    let decrypted = CryptoJS.AES.decrypt(data, salt);
    let encode = decrypted.toString(CryptoJS.enc.Utf8);
    return encode;
  },
};
export default Crypto;
