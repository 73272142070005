export const SendMessage = function (messageData, timeOutValue) {
  return new Promise(function (resolve, reject) {
    let controller = new AbortController();
    let signal = controller.signal;
    let url =
      "https://www2.shippersedge.com/public/carrier/newMessage.cfm?wsdl";
    let request = new Request(url, {
      method: "POST",
      signal,
      body: messageData,
    });
    setTimeout(() => controller.abort(), timeOutValue);
    fetch(request)
      .then((response) => {
        //   console.log("debug SendMessageStatus response", response);
        if (!response.ok) {
          console.error("SendMessage.js error", response.statusText, response);
          throw Error(response.statusText);
        }

        return response.json();
      })
      .then((response) => {
        resolve(response.message);
      })
      .catch((err) => {
        console.error("SendMessage API Request failed", err);
        resolve("Aborted");
      });
  });
};
