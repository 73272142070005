import Crypto from "../Crypto/Crypto";
import history from "../History/History";

export const GetUserData = function () {
  if (localStorage.getItem("userData")) {
    let encUserData = localStorage.getItem("userData");
    let userData = JSON.parse(Crypto.decrypt(encUserData));
    return userData;
  } else {
    localStorage.clear();
    history.push("/");
  }
};
