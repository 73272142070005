import React, { useState, useEffect } from "react";

const Paginator = (props) => {
  const [paginatedShipments, setPaginatedShipments] = useState(null);
  const [selectedPage, setSelectedPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [paginationNeeded, setPaginationNeeded] = useState(false);

  useEffect(() => {
    if (props.shipments) {
      const shipmentsPerPage = 100;
      const totalShipments = props.shipments.length;
      let newBigArray = [];
      let newSmallArray = [];

      if (props.shipments.length <= shipmentsPerPage) {
        props.setSelectedPaginatedShipments(props.shipments);
        setPaginationNeeded(false);
      } else {
        props.shipments.forEach((shipment, index) => {
          // Make a new small array of shipments if we've filled it with the max shipments per page
          if (newSmallArray.length >= shipmentsPerPage) {
            let copyOfNewSmallArray = [...newSmallArray];
            newBigArray.push(copyOfNewSmallArray);
            newSmallArray.length = 0;
          }
          // Otherwise just put the shipment in the new small array
          newSmallArray.push(shipment);
          // If we're at the last shipment then add the not entirely full small array to the big array
          if (index === totalShipments - 1) {
            let copyOfNewSmallArray = [...newSmallArray];
            newBigArray.push(copyOfNewSmallArray);
            newSmallArray.length = 0;
          }
        });
        setPaginatedShipments(newBigArray);
        setTotalPages(newBigArray.length);
        props.setSelectedPaginatedShipments(newBigArray[0]);
        setSelectedPage(0);
        setPaginationNeeded(true);
      }
    }
  }, [props.shipments, props.updateTime]); // eslint-disable-line

  const selectPage = (page) => {
    setSelectedPage(page - 1);
    props.setSelectedPaginatedShipments(paginatedShipments[page - 1]);
  };

  const previousPage = () => {
    setSelectedPage(selectedPage - 1);
    props.setSelectedPaginatedShipments(paginatedShipments[selectedPage - 1]);
  };

  const nextPage = () => {
    setSelectedPage(selectedPage + 1);
    props.setSelectedPaginatedShipments(paginatedShipments[selectedPage + 1]);
  };

  //
  //  UI Components
  //
  /* eslint-disable */
  // ESLint doesn't approve of href="#" but I think it fine here, disabling ESLint from here down
  const PreviousBtn = () => {
    if (selectedPage === 0) {
      return (
        <li className="page-item disabled">
          <a className="page-link" href="#">
            Previous
          </a>
        </li>
      );
    } else {
      return (
        <li className="page-item ">
          <a
            className="page-link MutedLinkTextColor"
            onClick={() => previousPage()}
            href="#"
          >
            Previous
          </a>
        </li>
      );
    }
  };

  const NextBtn = () => {
    if (selectedPage === totalPages - 1) {
      return (
        <li className="page-item disabled">
          <a className="page-link" href="#">
            Next
          </a>
        </li>
      );
    } else {
      return (
        <li className="page-item ">
          <a
            className="page-link MutedLinkTextColor"
            onClick={() => nextPage()}
            href="#"
          >
            Next
          </a>
        </li>
      );
    }
  };

  const PageBackTwo = () => {
    if (selectedPage > 1) {
      let buttonNumber = selectedPage - 1;
      return (
        <li className="page-item">
          <a
            className="page-link MutedLinkTextColor"
            href="#"
            onClick={() => selectPage(buttonNumber)}
          >
            {buttonNumber}
          </a>
        </li>
      );
    } else {
      return null;
    }
  };

  const PageBackOne = () => {
    if (selectedPage > 0) {
      let buttonNumber = selectedPage;
      return (
        <li className="page-item">
          <a
            className="page-link MutedLinkTextColor"
            href="#"
            onClick={() => selectPage(buttonNumber)}
          >
            {buttonNumber}
          </a>
        </li>
      );
    } else {
      return null;
    }
  };

  const CurrentPage = () => {
    return (
      <li className="page-item active" aria-current="page">
        <span className="page-link MutedLinkTextColor">
          {selectedPage + 1}
          <span className="sr-only"></span>
        </span>
      </li>
    );
  };

  const PageForwardOne = () => {
    if (selectedPage < totalPages - 1) {
      let buttonNumber = selectedPage + 2;
      return (
        <li className="page-item">
          <a
            className="page-link MutedLinkTextColor"
            href="#"
            onClick={() => selectPage(buttonNumber)}
          >
            {buttonNumber}
          </a>
        </li>
      );
    } else {
      return null;
    }
  };

  const PageForwardTwo = () => {
    if (selectedPage < totalPages - 2) {
      let buttonNumber = selectedPage + 3;
      return (
        <li className="page-item">
          <a
            className="page-link MutedLinkTextColor"
            href="#"
            onClick={() => selectPage(buttonNumber)}
          >
            {buttonNumber}
          </a>
        </li>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {/* Paginator Debug - {props.shipments.length} - {selectedPage} -{totalPages} */}
      {paginationNeeded &&
      props.portalMode !== "Loading" &&
      props.portalMode !== "SearchResults" ? (
        <>
          {" "}
          <nav aria-label="pagination">
            <ul className="pagination pagination-sm">
              <PreviousBtn />
              <PageBackTwo />
              <PageBackOne />
              <CurrentPage />
              <PageForwardOne />
              <PageForwardTwo />
              <NextBtn />
            </ul>
          </nav>
        </>
      ) : null}
    </div>
  );
};

export default Paginator;
