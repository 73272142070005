import React, { useEffect, useState } from "react";

const CollectionNav = (props) => {
  const [clickedNav, setClickedNav] = useState(null);

  useEffect(() => {
    if (props.collections.length) {
      props.setSelectedCollection(props.collections[0].name);
      setClickedNav(props.collections[0].name);
    }
  }, [props.collections]); // eslint-disable-line

  const SetCollectionNav = (event) => {
    props.setSelectedCollection(event.currentTarget.textContent);
    setClickedNav(event.currentTarget.textContent);
  };

  if (props.portalMode === "SearchResults") {
    return (
      <div className="d-flex mb-3">
        <span
          className="TextLink"
          onClick={() => props.resetFromSearchToCollectionMode()}
        >
          Return to All Orders
        </span>
      </div>
    );
  } else {
    return (
      <>
        <div className="d-flex border-bottom mt-2">
          {props.collections.map((item) => {
            if (item.name === clickedNav) {
              return (
                <span
                  key={item.name}
                  className="RegularText Pointer flex-fill text-center SelectedNavText"
                  onClick={(event) => SetCollectionNav(event)}
                >
                  {item.name}
                </span>
              );
            } else {
              return (
                <span
                  key={item.name}
                  className="RegularText Pointer flex-fill text-center"
                  onClick={(event) => SetCollectionNav(event)}
                >
                  {item.name}
                </span>
              );
            }
          })}
          {/* ({filteredShipments.length}) */}
        </div>
      </>
    );
  }
};

export default CollectionNav;
