import { useState, useContext, useEffect } from 'react'
import UserContext from '../../Services/Context/UserContext';

// useRenderStatus is a custom hook to determine if a component should render based on if the component name is in the user.fields (in the DB consigneeCollections.fields entery) array. 

function useRenderStatus(componentName) {
    const user = useContext(UserContext);
    const [render, setRender] = useState(false);
    useEffect(() => {
        if (user && componentName) {
            if (user.fields) {
                if (JSON.parse(user.fields).includes(componentName)) {
                    setRender(true);
                } else {
                    setRender(false);
                }
            } else {
                setRender(true);
            }
        }
    }, [user, componentName])
    return render
}

export default useRenderStatus;