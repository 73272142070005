import React from "react";

const CarrierName = (props) => {
  return (
    <>
      <span className="SmallText"> Carrier Name:</span>
      <br />
      {props.carrier.name ? (
        <span className="RegularText">{props.carrier.name}</span>
      ) : (
        <span className="RegularText GreyText">No Carrier Assigned</span>
      )}
    </>
  );
};

export default CarrierName;
