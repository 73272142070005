import React from "react";
import useRenderStatus from "../../../../Services/Hooks/useRenderStatus";

const DeliveryDateQ001 = (props) => {
    const render = useRenderStatus('DeliveryDateQ001');
    if (render) {
        return (
            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-9 my-auto">Date Delivered:</div>
                        <div className="col">
                            <input name="dates.deliveryDate" type="date" className="form-control form-control form-control-sm" value={props.deliveryDateQ001} onChange={(event) => props.setDeliveryDateQ001(event.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    }
};

export default DeliveryDateQ001;
