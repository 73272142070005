import React, { useContext, useState, useEffect } from "react";

import UserContext from "../../Services/Context/UserContext";
import ProfileModal from "../../Components/ProfileModal/ProfileModal";
import Message from "../../Components/Message/Message";
import SiteSwitcher from "../../Components/SiteSwitcher/SiteSwitcher";

const Nav = (props) => {
  // User Conext data
  const contextUser = useContext(UserContext);
  const [user, setuser] = useState(null);
  const [logo, setlogo] = useState("");

  // Set User
  useEffect(() => {
    if (contextUser !== null) {
      setuser(contextUser);
    }
  }, [contextUser]);

  // Set Logo
  useEffect(() => {
    if (user !== null) {
      setlogo(user.logo);
    }
  }, [user]);

  function ShowMessage() {
    if (!!user && user.usercode === 'acmebumn') {
      return <Message />
    }
    return null
  }

  return (
    <nav className="sticky-top NavDiv navbar NavBorderBottom">
      <img
        className="nav-item navbar-brand mr-auto"
        src={logo}
        alt="logo"
        height="60vh"
      ></img>
      <div className="ml-3">
        <SiteSwitcher
          setUserData={props.setUserData}
          locationCodeNames={props.locationCodeNames}
        />
      </div>
      <div className="ml-auto flex-grow-1 d-flex justify-content-end">
        <ShowMessage />
        <ProfileModal />
      </div>
    </nav>
  );
};

export default Nav;
