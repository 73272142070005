// Date Formatter is for converting to varoius human friendly date layouts
// Note that moment will throw errors and complain with the converted "YYYY-MM-DD" format that we process on incomming shipments, so we provide the format here and convert it to whatever human friendly format we want

import * as moment from "moment";

export const DateFormatter = (date, format) => {
  if (date === null || date === "0001-01-01T00:00:00") {
    return "";
  } else if (format === "friendly") {
    date = moment(date, "YYYY-MM-DD").format("MMM Do YYYY");
    return date;
  } else {
    date = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
    return date;
  }
};

export default DateFormatter;
