import React, { useState, useEffect } from "react";
import Nav from "../../Components/Nav/Nav";
import YourOrders from "../../Components/YourOrders/YourOrders";
import Search from "../../Components/Search/Search";
import CollectionNav from "../../Components/CollectionNav/CollectionNav";
import Auth from "../../Services/Auth/";
import Body from "./Components/Body/Body";
import { UserContextProvider } from "../../Services/Context/UserContext";
import SortUI from "./Components/SortUI/SortUI";
import Helpers from "../../Services/Helpers/";
import GetSingleShipmentByID from "../../Services/API/GetSingleShipmentByID";
import VersionChecker from "../../Components/VersionChecker/VersionChecker";
import Paginator from "../Dashboard/Components/Paginator/Paginator";
import ShipmentsUpdatedCheck from "./Components/ShipmentsUpdatedCheck/ShipmentsUpdatedCheck";

const Dashboard = () => {
  const [collections, setCollections] = useState([
    {
      name: "All Shipments",
      filter: "shipment === shipment",
    },
  ]);
  const [portalType, setPortalType] = useState("CustomerPortal");
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [userData, setUserData] = useState(null);

  // Shipments and related shipments states
  const [initalShipments, setInitalShipments] = useState(null);
  const [shipmentsJSON, setShipmentsJSON] = useState("default");
  const [shipments, setShipments] = useState(null);
  const [selectedPaginatedShipments, setSelectedPaginatedShipments] = useState(
    null
  );

  const [searchResults, setsearchResults] = useState([]);
  const [searchType, setsearchType] = useState("refNumber");
  const [searchValue, setSearchValue] = useState("");
  const [searchValue2, setSearchValue2] = useState("");
  const [portalMode, setPortalMode] = useState("Loading");
  const [locationCodeNames, setlocationCodeNames] = useState(null);

  // Sorting States
  const [sortMethod, setsortMethod] = useState("delDate");
  const [sortDirection, setsortDirection] = useState("Descending");
  const [sortChanged, setsortChanged] = useState("");

  // Timestamp of last time new shipments were recived
  const [shipmentsLastUpdated, setShipmentsLastUpdated] = useState(null);

  // Last update state (for debugging)
  const [updateTime, setupdateTime] = useState(0);

  //
  // Auth Check & Set UserData & fields - This triggers once when Dashboard renders and generally doesn't again outside of a page refresh
  //
  useEffect(() => {
    if (window.location.href.includes("usda")) {
      setPortalType("USDAPortal");
      document.title = "ShippersEdge USDA Portal";
    }
    // console.log("useEffect one time only get userdata");
    // Recent login check
    Auth.AuthCheck.CheckLoginDate();
    // Get userData from localStorage
    let user = Auth.GetUserData.GetUserData();
    setUserData(user);
  }, []); // eslint-disable-line

  //
  // Get Shipments & Create Collection On StartUp - this should fires second after userData is set
  //
  useEffect(() => {
    // console.log("userData useEffect trigered");
    if (userData && userData.locationcodes) {
      setPortalMode("Loading");
      GetShipmentsForDashboard()
      setCollections(JSON.parse(userData.collection));
    } else {
      // Nothing
    }
  }, [userData]); // eslint-disable-line

  //
  // Sorting Shipments - Triggers on sortDirection or sortMethod changes & actually sorts
  //
  useEffect(() => {
    setPortalMode("Loading");
    async function AsyncFunctSort() {
      let filteredAndSortedShipments2 = await ShipmentProcessor(shipments);
      setShipments(filteredAndSortedShipments2);
    }
    AsyncFunctSort();
    setsortChanged(new Date());
    setupdateTime(new Date());
  }, [sortDirection, sortMethod]); // eslint-disable-line

  //
  //  Selected Collection Change
  //

  useEffect(() => {
    setPortalMode("Loading");
    if (initalShipments) {
      // console.log("seeing selectedColleciton Change");
      async function AsyncFunctCollectionChange() {
        let initalShipmentsProcessed = await ShipmentProcessor(initalShipments);
        setShipments(initalShipmentsProcessed);
        setupdateTime(new Date());
      }
      AsyncFunctCollectionChange();
    }
  }, [selectedCollection]); // eslint-disable-line

  //
  //  Get Shipments Stand Alone Function (used on startup and timed get shipments)
  // Right now this always triggers the shipmentsJSON !== block as the API is returning inconstant order of shipments, need to fix that, once that is resolved this should compare and identify if shipments actually changed and not trigger rerenders for every API call
  //
  const GetShipmentsForDashboard = async () => {
    // console.log("GetShipmentsForDashboard", shipmentsJSON);
    let gotShipmentsFromAPI = await Helpers.GetShipments.GetShipments(
      userData.locationcodes
    );

    if (shipmentsJSON !== JSON.stringify(gotShipmentsFromAPI)) {
      // console.log("GetShipmentsForDashboard saw updated data!");
      setInitalShipments(gotShipmentsFromAPI);
      setShipmentsJSON(JSON.stringify(gotShipmentsFromAPI));
      let SortedAndFilteredShipments = await ShipmentProcessor(
        gotShipmentsFromAPI
      );
      setShipments(SortedAndFilteredShipments);
      setupdateTime(new Date());
      setShipmentsLastUpdated(new Date());
      setPortalMode("Collections");
    } else {
      // console.log("GetShipmentsForDashboard saw NO updated data!");
      setShipmentsLastUpdated(new Date());
    }
  };

  //
  //  Shipments Processor, sorts and filters shipments
  //
  const ShipmentProcessor = async (shipments) => {
    if (shipments && shipments.length) {
      // Get Filter
      let filter = "";
      collections.forEach((collection) => {
        if (collection.name === selectedCollection) {
          filter = collection.filter;
        }
      });

      // Filter
      let filteredShipments = await Helpers.FilterShipments.FilterShipments(
        shipments,
        filter
      );

      // Sort
      let filteredAndSortedShipments = await Helpers.ShipmentSorter.ShipmentSorter(
        filteredShipments,
        sortMethod,
        sortDirection
      );

      return filteredAndSortedShipments;
    } else {
      // console.log("ShipmentProcessor no shipments");
      return null;
    }
  };

  //
  // Change portal status to loaded after shipments is updated & set locationCode names
  //
  useEffect(() => {
    if (portalMode === "SearchResults") {
      //Don't change collections if we are already in search mode to avoid getting kicked out of search on update
    } else {
      setPortalMode("Collections");
    }
    if (userData && shipments.length && !locationCodeNames) {
      setlocationCodeNames(
        Helpers.GetLocationCodeNames.GetLocationCodeNames(
          userData.locationcodes,
          shipments
        )
      );
    }
  }, [selectedPaginatedShipments]); // eslint-disable-line

  //
  //  Timed Get Shipments
  //  Need to include sort methods otherwise the first time GetShipmentsForDashboard runs it will sot using the sort methods when the interval was established, not updated values.    Need to find a better solution for this.
  //
  useEffect(() => {
    let interval = null;
    interval = setInterval(() => GetShipmentsForDashboard(), 180000);
    return () => {
      clearInterval(interval);
    };
  }, [userData, shipmentsJSON, sortMethod, sortDirection]); // eslint-disable-line

  //
  // Search Machine
  //
  const searchMachine = () => {
    console.log("searchMachine", searchType, searchValue, searchValue2);
    setPortalMode("Loading");
    let searchResults = Helpers.SearchShipments.SearchShipments(
      shipments,
      searchType,
      searchValue,
      searchValue2
    );
    // console.log("searchResults", searchResults);

    setsearchResults(searchResults);
    setPortalMode("SearchResults");
  };

  //
  // Reset from Search Results mode Collection (standard) mode
  //
  const resetFromSearchToCollectionMode = () => {
    setsearchResults([]);
    setPortalMode("Collections");
    setsortChanged(new Date());
  };

  //
  //  Single shipmnt update request
  //  This is used to refresh the shipment after the user updates it so they can see updated docs and status.
  //
  const GetSingleShipmentRequestDashboard = async (bol, timeoutValue) => {
    let singleShipment = await GetSingleShipmentByID(bol, timeoutValue);
    //
    // May want to test for aborts / network timeouts and etc here, but for now single shipment responses have been fast / no isseus
    //
    let jsonIfiedSingleShipment = JSON.parse(
      JSON.stringify(singleShipment).replace(/\:null/gi, ':""') // eslint-disable-line
    );
    // console.log("jsonIfiedSingleShipment", jsonIfiedSingleShipment);
    let findExistingShipmentIndex = shipments.findIndex(
      (shipment) => shipment.id === jsonIfiedSingleShipment.id
    );
    // console.log("findExistingShipment", findExistingShipmentIndex);
    if (findExistingShipmentIndex === -1) {
      console.warn(
        "Updating single shipment, did not find it in the shipments array."
      );
    } else {
      // console.log("GetSingleShipmentRequestDashboard going to update");
      let newShipments = shipments;
      newShipments[findExistingShipmentIndex] = jsonIfiedSingleShipment;
      // console.log("newShipments", newShipments);
      setShipments(newShipments);
      setupdateTime(new Date());
      setShipmentsLastUpdated(new Date());
      // console.log("shipments updated?", shipments);
    }
  };

  return (
    <>
      <UserContextProvider value={userData}>
        <Nav setUserData={setUserData} locationCodeNames={locationCodeNames} />
        <div className="container">
          <VersionChecker />
          <ShipmentsUpdatedCheck shipmentsLastUpdated={shipmentsLastUpdated} />
          <div className="row">
            <div className="col-5">
              <YourOrders portalMode={portalMode} searchResultsLen={searchResults.length} />
            </div>

            <div className="col-7">
              <Search
                searchType={searchType}
                searchValue={searchValue}
                searchValue2={searchValue2}
                setsearchType={setsearchType}
                setSearchValue={setSearchValue}
                setSearchValue2={setSearchValue2}
                searchMachine={searchMachine}
              />
            </div>
          </div>
          {portalType === "USDAPortal" ? null : 
          <CollectionNav
            portalMode={portalMode}
            collections={collections}
            setSelectedCollection={setSelectedCollection}
            resetFromSearchToCollectionMode={resetFromSearchToCollectionMode}
          />
          }
          <SortUI
            portalMode={portalMode}
            sortMethod={sortMethod}
            setsortMethod={setsortMethod}
            sortDirection={sortDirection}
            setsortDirection={setsortDirection}
          />

          {/* {Date.parse(updateTime)} */}

          <Paginator
            shipments={shipments}
            setSelectedPaginatedShipments={setSelectedPaginatedShipments}
            updateTime={updateTime}
            portalMode={portalMode}
          />

          {/* <button onClick={() => console.log("shipmentsJSON", shipmentsJSON)}>
            Wut
          </button> */}

          <Body
            portalMode={portalMode}
            portalType={portalType}
            shipments={selectedPaginatedShipments}
            searchResults={searchResults}
            selectedCollection={selectedCollection}
            // collections={collections}
            GetSingleShipmentRequestDashboard={
              GetSingleShipmentRequestDashboard
            }
            sortChanged={sortChanged}
          />
        </div>
      </UserContextProvider>
    </>
  );
};

export default Dashboard;
