import React from "react";

const Status = (props) => {
  return (
    <>
      <span className="RegularTextBold">{props.status.code}</span>
      <br />
      <span className="SmallText">({props.status.description})</span>
    </>
  );
};

export default Status;
