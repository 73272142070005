import React, { useState } from "react";
import APIs from "../../../../Services/API";

const SubmitUpdate = (props) => {
  const [alertType, setalertType] = useState("");
  const [alertText, setalertText] = useState("");

  const Alert = () => {
    return (
      <div className={`${alertType}`} role="alert">
        {alertText}
      </div>
    );
  };

  const AlertStatusSetter = (type, text) => {
    setalertType(type);
    setalertText(text);
  };

  // Testing if the document upload form data is all there
  const DocumentUploadFormDataTester = (documentFormData) => {
    return new Promise(function (resolve, reject) {
      // Upload doc because both filetype and file are there
      if (documentFormData.has("fileType") && documentFormData.has("file")) {
        resolve(true);
      }
      // File is there but filetime is not, prompt user to select file type
      if (!documentFormData.has("fileType") && documentFormData.has("file")) {
        resolve("missingFileType");
      }
      // No document to upload, move on
      if (!documentFormData.has("fileType") && !documentFormData.has("file")) {
        resolve(false);
      }
    });
  };

  // Checks to see if the existing text in Notes001 is the same as the most recent note to avoid resubmitting the same note value over and over, returns true if the text in Notes001 is different than the last message
  const CheckIfNote001IsNew = () => {
    // console.log("props.notes001", props.notes001);
    if (props.messages.length && props.notes001 !== "") {
      let filterOutOtherMessages = props.messages.filter(
        (message) => message.usercode === "CustomerPortal"
      );
      if (filterOutOtherMessages.length) {
        let mostRecentDate = props.messages.reduce((a, b) => {
          return new Date(a.entered_date) > new Date(b.entered_date) ? a : b;
        });
        //   console.log("mostRecentDate", mostRecentDate);
        if (props.notes001 !== mostRecentDate.entryDetails) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else if (!props.messages.length && props.notes001 !== "") {
      return true;
    } else {
      return false;
    }
  };

  // Build FormData for Notes001 message
  const BuildFormdataForNotes001 = () => {
    const messageData = new FormData();
    messageData.append("shipmentId", props.shipmentBOL.toString());
    messageData.append("usercode", "CustomerPortal");
    messageData.append("contactName", props.user.name);
    messageData.append("message", props.notes001);
    return messageData;
  };

  const SequentialStart = async () => {
    AlertStatusSetter("alert alert-dark", "Verifying data...");
    //
    //  Shipment Update
    //
    const FormData = await props.BuildFormData();

    AlertStatusSetter("alert alert-dark", "Sending Update...");
    const updateData = await APIs.UpdateShipment.UpdateShipment(
      FormData,
      10000
    );

    if (updateData === "Aborted") {
      AlertStatusSetter("alert alert-dark", "Sending Update, retry...");
      const updateData2 = await APIs.UpdateShipment.UpdateShipment(
        FormData,
        15000
      );
      if (updateData2 === "Aborted") {
        AlertStatusSetter(
          "alert alert-dark",
          "Sending Update, second retry..."
        );
        const updateData3 = await APIs.UpdateShipment.UpdateShipment(
          FormData,
          30000
        );
        if (updateData3 === "Aborted") {
          AlertStatusSetter(
            "alert alert-warning",
            "Updating shipment failed due to network timeout.  Please try again later."
          );
          return false;
        }
      }
    }

    //
    // Send new message if Notes001 has been filled out
    //
    let sendNote001 = false;
    // Check if there are messages and if they are relivant to the Customer Portal
    sendNote001 = CheckIfNote001IsNew();

    if (sendNote001) {
      AlertStatusSetter("alert alert-dark", "Sending Note.");
      let messageFormData = BuildFormdataForNotes001();
      let messageSendResults1 = await APIs.SendMessage.SendMessage(
        messageFormData,
        10000
      );
      if (messageSendResults1 === "Aborted") {
        AlertStatusSetter("alert alert-dark", "Sending Note, retry...");
        let messageSendResults2 = await APIs.SendMessage.SendMessage(
          messageFormData,
          20000
        );
        if (messageSendResults2 === "Aborted") {
          AlertStatusSetter("alert alert-dark", "Sending Note, retry 2...");
          let messageSendResults3 = await APIs.SendMessage.SendMessage(
            messageFormData,
            40000
          );
          if (messageSendResults3 === "Aborted") {
            AlertStatusSetter(
              "alert alert-warning",
              "Sending Note failed due to network timeout.  Please try again later."
            );
          } else {
            AlertStatusSetter("alert alert-dark", "Note Sent.");
          }
        } else {
          AlertStatusSetter("alert alert-dark", "Note Sent.");
        }
      } else {
        AlertStatusSetter("alert alert-dark", "Note Sent.");
      }
    }

    //
    // Document Upload
    //
    AlertStatusSetter("alert alert-dark", "Checking for document upload...");
    const documentFormData = await props.BuildFormDataDocumentUpload();
    AlertStatusSetter("alert alert-dark", "Checking for document upload 2...");

    const checkDocumentFormData = await DocumentUploadFormDataTester(
      documentFormData
    );

    // All the form data is there to upload a document, go
    AlertStatusSetter("alert alert-dark", "Uploading Document...");
    if (checkDocumentFormData === true) {
      const uploadDocument = await APIs.UploadDocument.UploadDocument(
        documentFormData,
        10000
      );

      if (uploadDocument === "Aborted") {
        AlertStatusSetter("alert alert-dark", "Uploading Document, retry...");
        const uploadDocument2 = await APIs.UploadDocument.UploadDocument(
          documentFormData,
          20000
        );
        if (uploadDocument2 === "Aborted") {
          AlertStatusSetter(
            "alert alert-dark",
            "Uploading Document, second retry..."
          );
          const uploadDocument3 = await APIs.UploadDocument.UploadDocument(
            documentFormData,
            30000
          );
          if (uploadDocument3 === "Aborted") {
            AlertStatusSetter(
              "alert alert-warning",
              "Upload Document failed due to network timeout.  Please try again later."
            );
          }
        }
      }
    }
    // Missing document type stop and alert
    if (checkDocumentFormData === "missingFileType") {
      AlertStatusSetter(
        "alert alert-warning",
        "Missing Document Type, Please Select Document Type..."
      );
      return false;
    }

    //
    // Request update for this shipment
    //
    let requestUpdateThisShipment = await props.GetSingleShipmentRequestDashboard(
      props.shipmentBOL,
      10000
    );
    if (requestUpdateThisShipment === "Aborted") {
      console.warn("SubmitUpdate.js single shipment update aborted.");
    }
    AlertStatusSetter("alert alert-success", "Shipment Updated Successfully!");
  };

  return (
    <>
      <Alert />
      <button
        type="button"
        className="btn btn-success"
        onClick={() => SequentialStart()}
      >
        Update
      </button>

      <button
        type="button"
        className="btn btn-danger"
        onClick={() => props.onClick(false)}
      >
        Close
      </button>
    </>
  );
};

export default SubmitUpdate;
