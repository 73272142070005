import React from "react";
import DateFormatter from "../../../../../Services/Helpers/DateFormatter";

const ShipDate = (props) => {
  if (
    props.dates.actualPickup !== "" &&
    props.dates.actualPickup !== "Invalid date"
  ) {
    return (
      <>
        <span className="SmallText">Ship Date:</span> <br />
        <span className="RegularText">
          {DateFormatter(props.dates.actualPickup, "friendly")}
        </span>
      </>
    );
  } else if (
    props.dates.appointment !== "" &&
    props.dates.appointment !== "Invalid date"
  ) {
    return (
      <>
        <span className="SmallText">Appointment Ship Date:</span> <br />
        <span className="RegularText">
          {DateFormatter(props.dates.appointment, "friendly")}
        </span>
      </>
    );
  } else if (
    props.dates.available1 !== "" &&
    props.dates.available1 !== "Invalid date"
  ) {
    return (
      <>
        <span className="SmallText">Planned Ship Date:</span> <br />
        <span className="RegularText">
          {DateFormatter(props.dates.available1, "friendly")}
        </span>
      </>
    );
  } else {
    return (
      <>
        <span className="SmallText">Planned Ship Date:</span> <br />
        <span className="RegularText">No date available.</span>
      </>
    );
  }
};

export default ShipDate;

//  {DateFormatter(props.shipment.shipper.dates.actualPickup, "friendly")}
