import React from "react";

const RefernceNumbers = (props) => {
  return (
    <>
      <h5 className="card-title LargeText ">
        Order Number: {props.shipment.usercontrol}{" "}
      </h5>
      <div className="card-subtitle text-muted">BOL: {props.shipment.id}</div>
      {props.shipment.trackingNumber ? (
        <>
          <span className="card-subtitle text-muted">
            Pro: {props.shipment.trackingNumber}
          </span>
          <br />
        </>
      ) : null}
    </>
  );
};

export default RefernceNumbers;
