import React, { useState } from "react";
import BootStrapModal from "react-bootstrap/Modal";
import Auth from "../../Services/Auth/";

const ProfileModal = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let version = localStorage.getItem("version");


  return (
    <>
      {/* 
        Profile Button
     */}
      <button
        type="button"
        className="btn btn-sm btn-outline-secondary"
        onClick={handleShow}
      >
        Profile
      </button>
      {/* 
            Modal
    */}
      <BootStrapModal show={show} onHide={handleClose} size="xl">
        <BootStrapModal.Header closeButton>
          <BootStrapModal.Title>Profile</BootStrapModal.Title>
        </BootStrapModal.Header>
        <div className="modal-body">Current Version: {version}</div>
        <div className="modal-footer PrimaryModalFooterExtraHeight">
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => Auth.LogOut.LogOut()}
          >
            Logout
          </button>

          <button
            type="button"
            className="btn btn-danger"
            onClick={() => setShow(false)}
          >
            Close
          </button>
        </div>
      </BootStrapModal>
    </>
  );
};

export default ProfileModal;
