export const GetLocationCodeNames = (locationCodes, shipments) => {
  let locationCodeNames = [];
  let tmpLocationCodeName = "";
  if (locationCodes.includes(",")) {
    let locationCodesArray = locationCodes.split(",");
    let newLocationCodeNames = locationCodesArray.map((code) => {
      shipments.some((shipment) => {
        if (shipment.consignee.id === code) {
          tmpLocationCodeName = shipment.consignee.name + shipment.consignee.address.line1;
          return tmpLocationCodeName;
        }
        return false;
      })

      return tmpLocationCodeName;
    })
    return newLocationCodeNames;
  } else {
    shipments.some((shipment) => {
      if (shipment.consignee.id === locationCodes) {
        tmpLocationCodeName = shipment.consignee.name;
        return true;
      }
      return false;
    });
    locationCodeNames.push(tmpLocationCodeName);
    // console.log("no", locationCodeNames);
    return locationCodeNames;
  }
};

export default GetLocationCodeNames;
