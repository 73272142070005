// This is a basic yes no component.
// Just pass this component a function called "setYesNo" and it will use it to set "yes", "no", or null if they unselect their choices

import React, { useState } from "react";

const YesNoButtons = (props) => {
  const [yesBtnType, setyesBtnType] = useState("btn-outline-success");
  const [noBtnType, setnoBtnType] = useState("btn-outline-danger");

  const setParentFunctionYN = (YN) => {
    props.setYesNo(YN);
  };

  // Yes Functions
  const setYesFilled = () => {
    setyesBtnType("btn-success");
    setNoOutline();
  };
  const setYesOutline = () => {
    setyesBtnType("btn-outline-success");
  };

  const clickYes = () => {
    if (yesBtnType === "btn-outline-success") {
      setYesFilled();
      setParentFunctionYN("yes");
    } else {
      setYesOutline();
      setParentFunctionYN(null);
    }
  };

  // No Functions
  const setNoFilled = () => {
    setnoBtnType("btn-danger");
    setYesOutline();
  };
  const setNoOutline = () => {
    setnoBtnType("btn-outline-danger");
  };
  const clickNo = () => {
    if (noBtnType === "btn-outline-danger") {
      setNoFilled();
      setParentFunctionYN("no");
    } else {
      setNoOutline();
      setParentFunctionYN(null);
    }
  };

  return (
    <>
      <div className="btn-toolbar">
        <div className="btn-group mr-2" role="group" aria-label="First group">
          <button
            type="button"
            className={`btn ${yesBtnType}`}
            onClick={() => clickYes()}
          >
            Yes
          </button>
        </div>
        <button
          type="button"
          className={`btn ${noBtnType}`}
          onClick={() => clickNo()}
        >
          No
        </button>
      </div>
    </>
  );
};

export default YesNoButtons;
