import Crypto from "../Crypto/Crypto";
import history from "../History/History";

// Check if last login was more than 7 days ago (604800000) and if so clear storage and force login again
export const CheckLoginDate = function () {
  if (localStorage.getItem("loginTime")) {
    let today = new Date();
    let lastLoginLocalStorage = localStorage.getItem("loginTime");
    let lastLogin = new Date(JSON.parse(Crypto.decrypt(lastLoginLocalStorage)));
    let differnce = today - lastLogin;
    if (differnce > 604800000) {
      localStorage.clear();
      history.push("/");
    } else {
      return true;
    }
  } else {
    localStorage.clear();
    history.push("/");
  }
};
