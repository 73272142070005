import React, { useState } from "react";
// import Email from "../../../SmallComponents/Input01";
// import PasswordRecoveryCode from "../../../SmallComponents/Input01";
// import Password1 from "../../../SmallComponents/Input01";
// import Password2 from "../../../SmallComponents/Input01";
// import PasswordRecovery from "../../../APIs/PasswordRecovery";

import Alert from "react-bootstrap/Alert";

function ForgotPassword(props) {
  const [step, setStep] = useState(0);
  const [status, setStatus] = useState("default");
  const [email, setEmail] = useState(props.email);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordRecoveryCode, setPasswordRecoveryCode] = useState("");

  React.useEffect(() => {
    setEmail(props.email);
  }, [props.email]);

  var linkStyle = {
    cursor: "pointer",
    color: "#4D7898",
    textDecoration: "underline",
  };
  var hideStyle = {
    display: "none",
  };

  var outlineStyle = {
    borderRadius: "5px",
    backgroundColor: "#DCDCDC",
    padding: "10px",
  };

  //   Conditional Rendering Instructional UI (alert fields based on status, API returns)
  function errorsAndStatus() {
    if (status === "default") {
      return null;
    }
    if (status === "enterEmail") {
      return (
        <>
          <Alert variant="info">
            Enter yor email address and we will Email you a Password Recovery
            Code. <br />
          </Alert>
          <span
            style={linkStyle}
            onClick={(event) => {
              setStep(2);
              setStatus("enterRecoveryCode");
            }}
          >
            I already have a Password Recovery Code!
          </span>
        </>
      );
    }
    if (status === "enterRecoveryCode") {
      return (
        <Alert variant="info">
          Enter the Password Recovery Code you recieved in your email here and
          set your new password.
        </Alert>
      );
    }
    if (status === "enterNewPassword") {
      return <Alert variant="info">Enter your new Password.</Alert>;
    }
    if (status === "noEmail") {
      return (
        <Alert variant="warning">
          Uh oh!
          <br />
          We can't find {email} in our records, perhaps you entered it
          incorrectly?
        </Alert>
      );
    }
    if (status === "accountDisabled") {
      return (
        <Alert variant="warning">
          The email address assoicated with that account is disabled. <br />
          Please contact your customer with any questions.
        </Alert>
      );
    }
    if (status === "multipleRecords") {
      return (
        <Alert variant="multipleRecords">
          Please contact support@shippersedge.com for further assistance.
        </Alert>
      );
    }
    if (status === "successCheckEmail") {
      return (
        <Alert variant="success">
          Check your Email!
          <br />
          <br />
          When the email arrives with the Password Recovery Code, enter it in
          the field above and set your new password.
        </Alert>
      );
    }
    if (status === "noResetCodeMatch") {
      return (
        <Alert variant="warning">
          The Password Recovery Reset code you entered did not match. Please try
          again, or{" "}
          <span style={linkStyle} onClick={() => setStep(1)}>
            request a new code
          </span>
          .
        </Alert>
      );
    }
    if (status === "expiredCode") {
      return (
        <Alert variant="warning">
          The Password Recovery Code has expired. Please request a new Password
          Recovery Code.
        </Alert>
      );
    }
    if (status === "successNewPasswordSet") {
      return <Alert variant="success">Your new password has been set!</Alert>;
    }
    if (status === "unknownError") {
      return (
        <Alert variant="warning">
          An unknown error has occured. <br />
          Please contact ShipperEdge support
        </Alert>
      );
    }
  }

  const getPasswordRecoveryCode = (email) => {
    return new Promise(function (resolve, reject) {
      var myHeaders = new Headers();
      var formdata = new FormData();
      formdata.append("email", email);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        "https://www2.shippersedge.com/public/carrier/consigneePortalPasswordReset.cfm?wsdl",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          resolve(JSON.parse(result));
        })
        .catch((error) => console.error("error", error));
    });
  };

  const submitEmail = async (email) => {
    // console.log("submitEmail", email);
    const resultGetRecoveryCode = await getPasswordRecoveryCode(email);
    // console.log("resultGetRecoveryCode", resultGetRecoveryCode);

    if (resultGetRecoveryCode.status === "noEmail") {
      setStatus("noEmail");
    }
    if (resultGetRecoveryCode.status === "accountDisabled") {
      setStatus("accountDisabled");
    }
    if (resultGetRecoveryCode.status === "multipleRecords") {
      setStatus("multipleRecords");
    }
    if (resultGetRecoveryCode.status === "successCheckEmail") {
      setStep(2);
      setStatus("successCheckEmail");
    } else {
      setStatus("unknownError");
    }
  };

  const submitNewPassword = (email, passwordRecoveryCode, password1) => {
    // console.log("submitNewPassword", email, passwordRecoveryCode, password1);
    return new Promise(function (resolve, reject) {
      var myHeaders = new Headers();

      var formdata = new FormData();
      formdata.append("email", email);
      formdata.append("resetCode", passwordRecoveryCode);
      formdata.append("533a0de3", password1);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      fetch(
        "https://www2.shippersedge.com/public/carrier/consigneePortalPasswordReset.cfm?wsdl",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => resolve(JSON.parse(result)))
        .catch((error) => console.error("error", error));
    });
  };

  const verifyPasswordMatchAndSubmit = async (
    email,
    passwordRecoveryCode,
    password1,
    password2
  ) => {
    if (email === "") {
      alert("Please fill out the email field.");
    } else if (password1 === "" || password2 === "") {
      alert("Please do not use blank passwords.");
    } else if (password1 !== password2) {
      alert("Both passwords do not match.");
    } else {
      const submitNewPasswordResult = await submitNewPassword(
        email,
        passwordRecoveryCode,
        password1
      );
      // console.log("submitNewPasswordResult", submitNewPasswordResult);
      if (submitNewPasswordResult.status === "noResetCodeMatch") {
        setStatus("noResetCodeMatch");
      }
      if (submitNewPasswordResult.status === "expiredCode") {
        setStatus("expiredCode");
        setStep(1);
      }
      if (submitNewPasswordResult.status === "successNewPasswordSet") {
        setStatus("successNewPasswordSet");
        setStep(0);
      } else {
        setStatus("unknownError");
      }
    }
  };

  // ForgotPassword UI
  if (step === 0) {
    return (
      <>
        <span
          style={linkStyle}
          onClick={() => {
            setStep(1);
            setStatus("enterEmail");
            // props.clearLoginError();
          }}
        >
          Forgot Password?
        </span>

        {errorsAndStatus()}
      </>
    );
  }
  if (step === 1) {
    return (
      <div style={outlineStyle}>
        <div className="input-group mb-3">
          <input
            className="form-control mb-3"
            id="passwordRecoveryEmailField"
            value={email}
            showHide="show"
            type="email"
            placeholder="Email Address"
            aria-label="Email Address"
            name="email"
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <button
          className="btn btn-secondary btn-block"
          onClick={() => {
            submitEmail(email);
          }}
        >
          Submit Email
        </button>

        {/* <PrimaryBtn
          btnName="Submit Email"
          showHide="show"
          onClick={() => {
            submitEmail(email);
          }}
        /> */}
        <br />

        {errorsAndStatus()}
      </div>
    );
  }

  if (step === 2) {
    return (
      <div style={outlineStyle}>
        {/* Chrome insists on filling out the first field on ANY input type, in this case it was adding an email to the recovery code field.   Chromme only fills out the first email field, same with password lower, the hidden inputs are meanningless.  */}
        <input type="password" style={hideStyle} />
        <input
          className="form-control mb-3"
          value={passwordRecoveryCode}
          id="passwordRecoveryCodeField"
          type="password"
          showHide="show"
          placeholder="Password Recovery Code"
          name="userNameRecovery"
          onChange={(event) => setPasswordRecoveryCode(event.target.value)}
        />
        <input type="password" style={hideStyle} />
        <input
          className="form-control mb-3"
          value={password1}
          showHide="show"
          type="password"
          placeholder="New Password"
          name="passwordRecovery1"
          onChange={(event) => setPassword1(event.target.value)}
        />
        <input
          className="form-control mb-3"
          value={password2}
          showHide="show"
          type="password"
          placeholder="New Password"
          name="passwordRecovery2"
          onChange={(event) => setPassword2(event.target.value)}
        />
        {/* In case someone click's "I have a code" but hasn't entered their email (another browser maybe) */}

        <input type="email" style={hideStyle} />
        <input
          className="form-control mb-3"
          id="passwordRecoveryEmailFieldAdditional"
          value={email}
          showHide="show"
          type="email"
          placeholder="Confirm Email Address"
          name="email"
          onChange={(event) => setEmail(event.target.value)}
        />

        <button
          className="btn btn-secondary btn-block"
          onClick={() => {
            verifyPasswordMatchAndSubmit(
              email,
              passwordRecoveryCode,
              password1,
              password2
            );
          }}
        >
          Verify Password Recovery Code & New Password
        </button>
        {/* <PrimaryBtn
          btnName="Verify Password Recovery Code & New Password"
          showHide="show"
          onClick={() => {
            verifyPasswordMatchAndSubmit(
              email,
              passwordRecoveryCode,
              password1,
              password2
            );
          }}
        /> */}
        <br />

        {errorsAndStatus()}
      </div>
    );
  }
  if (step > 2) {
    setStep(0);
  }
}

export default ForgotPassword;
