// ShipDateSeletor simply selects the first available date by priority for the ShipmentSorter.js to use to sort with
// This operates much like ShipDate.js does when display date, prioritizing actual pickup, appointment, and then available date
// It defaults to Jan 1 1900 if no date is available so that the shipment can sort somehow

export const ShipDateSelector = (shipment) => {
  if (!shipment) {
    console.error("ShipDateSelector missing shipment");
    return "1900-01-01T00:00:00.000Z";
  }
  if (
    shipment.shipper.dates.actualPickup !== "" &&
    shipment.shipper.dates.actualPickup !== "Invalid date"
  ) {
    return shipment.shipper.dates.actualPickup;
  }

  if (
    shipment.shipper.dates.appointment !== "" &&
    shipment.shipper.dates.appointment !== "Invalid date"
  ) {
    return shipment.shipper.dates.appointment;
  }

  if (
    shipment.shipper.dates.available1 !== "" &&
    shipment.shipper.dates.available1 !== "Invalid date"
  ) {
    return shipment.shipper.dates.available1;
  } else {
    return "1900-01-01T00:00:00.000Z";
  }
};

export default ShipDateSelector;
