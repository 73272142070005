import React from 'react'

const Costs = (props) => {
    if (props) {
        return (
            <div className="mt-3">
                <span className="SmallText"> Estimated Cost:</span>
                <br />
                {props.billing.cost.estimated ? (
                    <span className="RegularText">${props.billing.cost.estimated.total}</span>
                ) : (
                        <span className="RegularText GreyText">No estimated cost at this time.</span>
                    )}
            </div>
        )
    } else {
        return null;
    }
}

export default Costs
