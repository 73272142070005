import React from 'react'
import useRenderStatus from "../../../../../Services/Hooks/useRenderStatus";

const Documents = (props) => {
    const render = useRenderStatus('UserUploadedDocuments001');
    if (render) {
    return (
        <>
            <span className="SmallText"> Documents {props.documents.length}:</span> 
            <br />
            {props.documents.map((item) => {
                return <><span key={item.id}><a href={item.url} rel="noopener noreferrer" target="_blank">{item.name}</a></span> <br/></>
            })}
        </>
    )
    } else {
        return null
    }
}

export default Documents
