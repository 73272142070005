import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "../../Pages/Login/Login";

import FourOhFour from "../../Pages/FourOhFour/FourOhFour";
import SignUp from "../../Pages/SignUp/SignUp";
import Dashboard from "../../Pages/Dashboard/Dashboard";
import OrderView from "../../Pages/OrderView/OrderView";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/Dashboard" exact component={Dashboard} />
      <Route path="/SignUp" exact component={SignUp} />
      <Route path="/order/:orderGUID" component={OrderView} />
      <Route component={Dashboard} />
    </Switch>
  );
}
