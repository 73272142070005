import React from "react";

const AlertBox = (props) => {
  if (props.showHide === "show") {
    return <div className={props.alertType}>{props.alertText}</div>;
  } else {
    return null;
  }
};

export default AlertBox;
