import React from "react";

const EmailForm = (props) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <p>
            Please enter the email address where you recieved the invitation to
            the Customer Portal.
          </p>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                Email
              </span>
            </div>
            <input
              type="email"
              className="form-control"
              placeholder=""
              aria-label="Email"
              aria-describedby="basic-addon1"
              onChange={(event) => props.setemail(event.target.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailForm;
