import React from "react";
import DateFormatter from "../../../../../Services/Helpers/DateFormatter";

const DeliveryDate = (props) => {
  if (
    props.dates.actualDelivery !== "" &&
    props.dates.actualDelivery !== "Invalid date"
  ) {
    return (
      <>
        <span className="SmallText">Delivery Date:</span> <br />
        <span className="RegularText">
          {DateFormatter(props.dates.actualDelivery, "friendly")}
        </span>
      </>
    );
  } else if (
    props.dates.estimatedDelivery !== "" &&
    props.dates.estimatedDelivery !== "Invalid date"
  ) {
    return (
      <>
        <span className="SmallText">Estimated Delivery Date: </span>
        <br />
        <span className="RegularText">
          {DateFormatter(props.dates.estimatedDelivery, "friendly")}
        </span>
      </>
    );
  } else if (
    props.dates.appointment !== "" &&
    props.dates.appointment !== "Invalid date"
  ) {
    return (
      <>
        <span className="SmallText">Appointment Delivery Date: </span>
        <br />
        <span className="RegularText">
          {DateFormatter(props.dates.appointment, "friendly")}
        </span>
      </>
    );
  } else if (
    props.dates.requestedDeliveryDate !== "" &&
    props.dates.requestedDeliveryDate !== "Invalid date"
  ) {
    return (
      <>
        <span className="SmallText">Requested Delivery Date: </span>
        <br />
        <span className="RegularText">
          {DateFormatter(props.dates.requestedDeliveryDate, "friendly")}
        </span>
      </>
    );
  } else if (
    props.dates.available1 !== "" &&
    props.dates.available1 !== "Invalid date"
  ) {
    return (
      <>
        <span className="SmallText">Planned Delivery Date: </span>
        <br />
        <span className="RegularText">
          {DateFormatter(props.dates.available1, "friendly")}
        </span>
      </>
    );
  } else {
    return (
      <>
        <span className="SmallText">Planned Delivery Date: </span>
        <br />
        <span className="RegularText">No date available.</span>
      </>
    );
  }
};

export default DeliveryDate;
