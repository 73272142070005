import React, { useState, useEffect } from "react";
import * as moment from "moment";

export const ShipmentsUpdatedCheck = (props) => {
  const [shipmentUpDateStatus, setShipmentUpDateStatus] = useState("ok");
  useEffect(() => {
    const interval = setInterval(() => {
      CheckShipmentUpdate();
    }, 600000);
    return () => clearInterval(interval);
  }, [props.shipmentsLastUpdated]); // eslint-disable-line

  // Check when the last time shipmens were updated
  const CheckShipmentUpdate = async () => {
    // console.log(
    //   "Checking if shipments were updated.",
    //   Date.parse(props.shipmentsLastUpdated),
    //   new Date().setHours(new Date().getHours() - 6)
    // );
    if (props.shipmentsLastUpdated !== null) {
      if (
        Date.parse(props.shipmentsLastUpdated) <
        new Date().setHours(new Date().getHours() - 6)
      ) {
        setShipmentUpDateStatus("6_Hours_Old");
      } else if (
        props.shipmentsLastUpdated <
        new Date().setHours(new Date().getHours() - 3)
      ) {
        setShipmentUpDateStatus("3_Hours_Old");
      } else {
        setShipmentUpDateStatus("ok");
      }
    } else {
      setShipmentUpDateStatus("ok");
    }
  };

  function RefreshPage() {
    window.location.reload();
  }

  function UpdatedOrNotUI() {
    if (shipmentUpDateStatus === "ok") {
      return null;
    }
    if (shipmentUpDateStatus === "3_Hours_Old") {
      return (
        <div
          className="alert alert-warning pointer"
          onClick={() => RefreshPage()}
          role="alert"
        >
          The Customer Portal has not recieved new shipments since{" "}
          {moment(props.shipmentsLastUpdated).format("LLL")}
          . <br />
          Click here to reload the page and recieve updated shipments.
        </div>
      );
    }
    if (shipmentUpDateStatus === "6_Hours_Old") {
      return (
        <div
          className="alert alert-warning pointer"
          onClick={() => RefreshPage()}
          role="alert"
        >
          The Customer Portal has not recieved new shipments since{" "}
          {moment(props.shipmentsLastUpdated).format("LLL")}
          . <br />
          Click here to reload the page and recieve updated shipments.
        </div>
      );
    } else {
      console.warn(
        "UpdatedShipmentsCheck, UpdatedOrNotUI has a status that it does not understand."
      );
      return null;
    }
  }

  return (
    <>
      <UpdatedOrNotUI />
    </>
  );
};

export default ShipmentsUpdatedCheck;
