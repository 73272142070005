import React from "react";

const ConsAddress = (props) => {
  return (
    <div className="SmallText card animated flex-fill">
      <div className="card-body">
        {props.consAddress.name ? (
          <>
            <span>{props.consAddress.name}</span> <br />
          </>
        ) : null}

        {props.consAddress.address.line1 ? (
          <>
            <span>{props.consAddress.address.line1}</span> <br />
          </>
        ) : null}
        {props.consAddress.address.line2 ? (
          <>
            <span>{props.consAddress.address.line2}</span> <br />
          </>
        ) : null}
        {props.consAddress.address.city &&
        props.consAddress.address.state &&
        props.consAddress.address.zip ? (
          <>
            <span>{props.consAddress.address.city}</span>,{" "}
            <span>{props.consAddress.address.state}</span>{" "}
            <span>{props.consAddress.address.zip}</span>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ConsAddress;
