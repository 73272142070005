import React, { useState } from "react";
import BootStrapModal from "react-bootstrap/Modal";
import Auth from "../../Services/Auth/";

const ProfileModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            {/*
        Profile Button
     */}
            <button
                type="button"
                className="btn btn-sm btn-outline-secondary mr-2"
                onClick={handleShow}
            >
                Message to Shipper
            </button>
            {/*
            Modal
    */}
            <BootStrapModal show={show} onHide={handleClose} size="xl">
                <BootStrapModal.Header closeButton>
                    <BootStrapModal.Title>Send a Message/Notice To Shipper</BootStrapModal.Title>
                </BootStrapModal.Header>
                <div className="modal-body">
                    <textarea cols="129" rows="5"></textarea>
                </div>
                <div className="modal-footer PrimaryModalFooterExtraHeight">

                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => setShow(false)}
                    >
                        Send
                    </button>
                </div>
            </BootStrapModal>
        </>
    );
};

export default ProfileModal;
