import React, { useState, useEffect } from "react";
import Username from "./Components/Username";
import Password from "./Components/Password";
import ForgotPasswordUI from "./Components/ForgotPasswordUI";
import AlertBox from "./Components/AlertBox";
import history from "../../Services/History/History";
import md5 from "blueimp-md5";
import Crypto from "../../Services/Crypto/Crypto";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [alertShowHide, setalertShowHide] = useState("hide");
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState("");
  const [portalName, setPortalName] = useState("Customer Portal");

  function setAlertBox(showHide, type, txt) {
    setalertShowHide(showHide);
    setAlertType(type);
    setAlertText(txt);
  }

  function resetAlertBox() {
    setalertShowHide("hide");
    setAlertType("");
    setAlertText("");
  }

  function submitSignIn() {
    resetAlertBox();
    return new Promise(function (resolve, reject) {
      if (!username || !password) {
        resolve("Empty Username or Password");
      } else {
      }
      let timeOutValue = 20000;
      let controller = new AbortController();
      let signal = controller.signal;
      let url = "https://api.shippersedge.com/v2/authenticate/consignee";
      let payload = JSON.stringify({
        email: username,
        password: md5(password).toUpperCase(),
      });
      let request = new Request(url, {
        method: "POST",
        signal,
        headers: { "Content-Type": "application/json" },
        body: payload,
      });
      setTimeout(() => controller.abort(), timeOutValue);
      fetch(request)
        .then((response) => {
          if (!response.ok) {
            console.error("Login.js error", response.statusText, response);
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((response) => {
          console.log("response", response);
          resolve(response);
        })
        .catch((err) => {
          console.error("Login API Request failed", err);
          resolve(err);
        });
    });
  }

  const SequentalFuction = async () => {
    const signInResult = await submitSignIn();
    if (signInResult === "Empty Username or Password") {
      setAlertBox(
        "show",
        "alert alert-danger",
        "Username or password is blank!"
      );
      return false;
    }
    if (signInResult.toString().includes("Error")) {
      setAlertBox(
        "show",
        "alert alert-danger",
        "Username or password incorrect!"
      );
      return false;
    }
    if (signInResult === "Error: Not Found") {
      setAlertBox(
        "show",
        "alert alert-danger",
        "Username or password incorrect!"
      );
      return false;
    }
    // Happy path
    if (typeof signInResult === "object") {
      let userData = JSON.stringify(signInResult);
      let loginTime = JSON.stringify(new Date());
      localStorage.setItem("userData", Crypto.encrypt(userData));
      localStorage.setItem("loginTime", Crypto.encrypt(loginTime));
      history.push("/Dashboard");
    }
    // console.log("signInResult", signInResult);
  };

  const onEnterKeyPressLogIn = (event) => {
    if (event.which === 13) {
      SequentalFuction();
    }
  };

  useEffect(() => {
    if (window.location.href.includes("usda")) {
      setPortalName("USDA Portal");
      document.title = "ShippersEdge USDA Portal";
    }
    
  })

  // if url contains usda set 
  

  return (
    <>
      <header className="App-header">
        <img src="shippersedge-logo.png" alt="logo" height="100px" />
        <span className="TitleText mb-3 mt-3">{portalName}</span>
        <br />
      </header>
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-5">
            <Username setUsername={setUsername} />
            <Password
              setPassword={setPassword}
              onEnterKeyPressLogIn={onEnterKeyPressLogIn}
            />
            <button
              type="submit"
              className="btn btn-primary btn-block loginBtn"
              onClick={() => SequentalFuction()}
            >
              Sign In
            </button>
            <br />
            <AlertBox
              showHide={alertShowHide}
              alertType={alertType}
              alertText={alertText}
            />
            <ForgotPasswordUI />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
