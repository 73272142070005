import React, { useContext, useState, useEffect } from "react"
import UserContext from "../../Services/Context/UserContext"
import useRenderStatus from "../../Services/Hooks/useRenderStatus"


const SiteSwitcher = (props) => {

  const contextUser = useContext(UserContext);
  const [initalLocations, setinitalLocations] = useState(null);
  const render = useRenderStatus('SiteSwitcher');

  useEffect(() => {
    if (contextUser && initalLocations === null) {
      setinitalLocations(contextUser.locationcodes);
    }
  }, [contextUser, initalLocations]);

  const ChangeLocation = (location) => {
    if (location === "allSites") {
      let newUserDataAllSites = JSON.parse(JSON.stringify(contextUser));
      newUserDataAllSites.locationcodes = initalLocations;
      //   console.log("newUserDataAllSites", newUserDataAllSites);
      props.setUserData(newUserDataAllSites);
    } else {
      //   console.log("changelocation", location);
      // Have to break the reference between objects here
      let newUserData1 = JSON.parse(JSON.stringify(contextUser));
      newUserData1.locationcodes = location;
      props.setUserData(newUserData1);
    }
  };

  if (
    contextUser &&
    initalLocations &&
    initalLocations.includes(",") &&
    props.locationCodeNames && render
  ) {
    let locationCodesArray = initalLocations.split(",");
    return (
      <div className="input-group input-group-sm">
        <select
          className="form-control form-control-sm mr-3"
          as="select"
          name="siteSelect"
          onChange={(event) => ChangeLocation(event.target.value)}
        >
          <option defaultValue="allSites" value="allSites">
            All Locations
          </option>

          {locationCodesArray.map((item, index) => (
            <option key={item} value={item}>
              {props.locationCodeNames[index]}
            </option>
          ))}
        </select>
      </div>
    );
  } else {
    return null;
  }
};

export default SiteSwitcher;
