import React from "react";
import Helpers from "../../../Services/Helpers";

const HUPackagesMod = (props) => {
  if (props.handlingUnits) {
    return (
      <p>
        {props.handlingUnits.map((hu, index) => (
          <span key={index + Helpers.QuickKey.QuickKey()}>
            {hu.quantity}, {hu.packaging}
            <br />
            {hu.items.map((item, index) => (
              <span key={index + Helpers.QuickKey.QuickKey()}>
                {item.quantity}, {item.description}
              </span>
            ))}
          </span>
        ))}
      </p>
    );
  } else {
    return null;
  }
};

export default HUPackagesMod;
