import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import GetSingleShipmentByID from "../../Services/API/GetSingleShipmentByID"
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
// import {
//     withScriptjs,
//     withGoogleMap,
//     GoogleMap,
//     Marker
// } from "react-google-maps"
import { format } from "date-fns"
import BeautyStars from 'beauty-stars'



const OrderView = () => {
    let { orderGUID } = useParams()
    const [order, setOrder] = useState(null)
    const [eta, setEta] = useState(new Date())
    const [stars, setStars] = useState(0)
    const [feedback, setFeedback] = useState(null)
    const [map, setMap] = React.useState(null)
    const [showFeedbackDiv, setShowFeedbackDiv] = useState('d-none')
    const [showFeedbackDeliveredDiv, setShowFeedbackDeliveredDiv] = useState('d-none')

    useEffect(() => {
        if (!order)
            getShipmentByGUID(orderGUID)
        
    })

    const getShipmentByGUID = async orderGUID => {
        const shipment = await GetSingleShipmentByID(orderGUID)
        console.log(shipment)
        if (shipment.status.location?.duration) {
            setEta(new Date(new Date().getTime() + shipment.status.location.duration * 60000))
        } else if (shipment.consignee.dates.estimatedDelivery) {
            setEta(new Date(shipment.consignee.dates.estimatedDelivery))
        } else {
            setEta(null)
        }
        setOrder(shipment)
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyA6PNkY8Fb4CNiH-xQKMQoRNUnjxUGnL-s',
      })

    
    
      
    
    //   const onLoad = React.useCallback(function callback(map) {
    //     // This is just an example of getting and using the map instance!!! don't just blindly copy!
    //     const bounds = new window.google.maps.LatLngBounds(center)
    //     map.fitBounds(bounds)
    
    //     setMap(map)
    //   }, [])
    
    //   const onUnmount = React.useCallback(function callback(map) {
    //     setMap(null)
    //   }, [])

    const MapWithAMarker = (props =>
        <div className="d-flex flex-stretch" style={{ height: "350px" }}>
            <GoogleMap
                mapContainerStyle={{width: '100%', height: '100%'}}
                center={{ lat: order?.consignee?.coordinates?.latitude, lng: order?.consignee?.coordinates?.longitude}}
                zoom={10}
                // onLoad={onLoad}
                // onUnmount={onUnmount}
                >
                <Marker
                    position={{ lat: +order?.consignee?.coordinates?.latitude, lng: +order?.consignee?.coordinates?.longitude }}
                />
                </GoogleMap>
            {/* Center the map keeping bounds for the two markers below and set the zoom to an appriate zoom based on the points */}
            {/* <GoogleMap GoogleMap
                defaultOptions={{ fullscreenControl: false, zoomControl: true, mapTypeControl: false, streetViewControl: false, panControl: true, draggable: false }}
                // center={{ lat: (Number(props.order?.consignee?.coordinates?.latitude) + Number(props.order?.status?.location?.latitude)) / 2, lng: (Number(props.order?.consignee?.coordinates?.longitude) + Number(props.order?.status?.location?.longitude)) / 2 }}
                zoom={12}
                // options={{mapId: "2121dadd53a50821"}}
            > */}
                {/* <Marker
                    position={{ lat: +props.order?.consignee?.coordinates?.latitude, lng: +props.order?.consignee?.coordinates?.longitude }}
                /> */}
                {/* {props.order?.status?.location?.latitude ?
                <Marker
                    icon={{
                        url: "https://img.icons8.com/color/48/000000/delivery.png",
                        scaledSize: new window.google.maps.Size(48, 48),
                    }}
                    position={{ lat: props.order?.status?.location?.latitude, lng: props.order?.status?.location?.longitude }}
                /> : null} */}
            {/* </GoogleMap> */}
        </div>
    )
    const saveRating = (value) => {
        setStars(value)
        setShowFeedbackDiv('d-inline')
    }

    const saveFeedback = (value) => {
        setFeedback(value)
    }

    const statusText = (code) => {
        switch (code) {
            case 'DEL':
                return 'Delivered!'
            case 'PU-Out':
                return 'Picked Up'
            case 'IT':
                return 'Your order is on the way!'
            default:
                return 'Processing Order'
        }
    }

    const statusSubText = (code) => {
        let text = ''
        switch (code) {
            case 'DEL':
                text += 'Your order has been delivered <a href="#">Where?</a>'
                break
            case 'PU-Out':
                text += 'Your order has been picked up and is on the way to you!'
                break
            case 'IT':
                text += ''
                break
            default:
                text += 'Your order is being processed and will be on the way soon!'
                break
        }
        if (eta) {
            text += ` Estimated Delivery: ${format(eta, "EEEE MMM dd, yy h:mm a")}`
        }
        return text
    }




    const submitFeedback = () => {
        setShowFeedbackDeliveredDiv('d-inline')
    }
    // What is the bootstrap class for small font?

    if (!isLoaded || !order) {
        return (
            <div>...Loading</div>
        )
    } else {
        return (
            <div className="container justify-content-center" style={{ maxWidth: "540px" }}>
                <h1 className="justify-content-center">
                    <img src="https://www2.shippersedge.com/images/logos/acme.jpg" className="m-2 text-center" height="50" />
                </h1>
                <div className="row justify-content-center mt-2">
                    <div className="card bg-light ml-3 mr-3 mt-0 w-100">
                        <div className="card-header" data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <h3 className="pl-1 pt-0 m-0">{statusText(order.status.code)}</h3>
                            <div className="pl-1 pt-1 m-0 font-size-md">{statusSubText(order.status.code)}</div>
                                {/* {
                                    (order.status.code === 'DEL') 
                                    ? <a href="#">Where?</a> 
                                    : null
                                } */}
                            {/* </div> */}
                        </div>

                        <div className="card-body p-0">
                            {
                                (order.status.code === 'DEL')
                                    ? <div className="d-flex justify-content-center align-items-center flex-wrap" style={{ height: "250px" }}>
                                        <div className="d-flex justify-content-center align-items-center flex-wrap" style={{ width: "350px" }}>
                                            <h3>How did we do?</h3>
                                            <BeautyStars
                                                className="m-0"
                                                value={stars}
                                                activeColor="#FFE800"
                                                onChange={value => saveRating(value)}
                                            />
                                            <div className={showFeedbackDiv}>
                                                <input type="text" name="feedback" id="feedback" placeholder="Please give us feedback here" className="mt-3 p-2" onChange={value => saveFeedback(value)} style={{ width: "15rem", fontSize: "1rem" }} />
                                                <button type="button" className="btn btn-secondary ml-1 p-2" style={{ borderWidth: 2, verticalAlign: "bottom" }} onClick={submitFeedback}>Submit</button>
                                                {/* <select name="feedback" id="feedback" className="m-2 p-2" onChange={value => saveFeedback(value)} style={{ fontSize: "1.2rem" }}>
                                                    <option>--- Please Provide Feedback ---</option>
                                                    <option>Delivery was late</option>
                                                    <option>Delivery was late</option>
                                                </select> */}
                                            </div>
                                            <div className={showFeedbackDeliveredDiv} style={{ fontSize: "1.1rem" }}>
                                                Thank you for your feedback!
                                            </div>
                                        </div>
                                    </div>
                                    : <MapWithAMarker
                                        // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA6PNkY8Fb4CNiH-xQKMQoRNUnjxUGnL-s&v=3.exp&libraries=geometry,drawing,places"
                                        // loadingElement={<div style={{ height: `100%` }} />}
                                        // containerElement={<div style={{ height: `300px` }} />}
                                        // mapElement={<div style={{ height: `100%` }} />}
                                        // order={order}
                                    />
                            }
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-2 h-25 mb-5">
                    <div className="col-12 ml-xs-1 mr-xs-1 pb-2 mt-xs-3 mt-md-1">
                        <div className="card bg-light w-300 h-100">
                            <div className="card-header h5">Delivery Information</div>
                            <div className="card-body p-3" style={{ fontSize: "0.75rem" }}>
                                <div>Order Number: {order.usercontrol}</div>
                                <div>Address: {order.consignee.address.line1} {order.consignee.address.line2} {order.consignee.address.city}, {order.consignee.address.state} {order.consignee.address.zip}</div>
                                <div>Scheduled Delivery: 9 AM - 11 AM</div>
                                <div>Delivery Weight: {order.details?.totalWeight} lbs</div>
                                <div>Total Pieces: {order.details?.totalPieces}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 ml-xs-1 mr-xs-1 pb-2 pt-xs-5 mt-xs-3 mt-md-1">
                        <div className="card bg-light w-300 h-100">
                            <div className="card-header h5">Manage Delivery</div>
                            <div className="card-body p-3" style={{ fontSize: "1rem" }}>
                                {(order.status.code !== 'DEL')
                                    ? <div>
                                        <div><a href="#">Update Delivery Instructions</a></div>
                                        <div><a href="#">Reschedule Delivery</a></div>
                                    </div>
                                    : null}
                                <div><a href="#">Need help with something else?</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

}

export default OrderView
