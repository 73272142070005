import ShipDateSelector from "./ShipDateSelector2";
import DeliveryDateSelector from "./DeliveryDateelector";

export const ShipmentSorter = async (shipments, sortMethod, sortDirecton) => {
  // console.log("ShipmentSorter.js", shipments, sortMethod, sortDirecton);
  //
  // BOL Sort
  //
  if (sortMethod === "BOL") {
    // console.log("shipment sorter BOL");
    if (sortDirecton === "Ascending") {
      // console.log("shipment sorter BOL, Ascending");
      const resortedData = shipments.sort(function (shipment1, shipment2) {
        return shipment1.id - shipment2.id;
      });
      // console.log("Shipmentorter.js, Ascending resorted", resortedData);
      return resortedData;
    }
    if (sortDirecton === "Descending") {
      // console.log("Shipmentorter.js, Descending");
      const resortedData = shipments.sort(function (shipment1, shipment2) {
        return shipment2.id - shipment1.id;
      });
      // console.log("Shipmentorter.js, Descending resorted", resortedData);
      return resortedData;
    } else {
      console.warn("Shipmentorter.js, invalid sortDirection for BOL");
      return [];
    }
  }

  //
  //  Pickup Date Sort (AKA Ship Date)
  //
  if (sortMethod === "pickupDate") {
    // console.log("shipment sorter pickupDate", sortDirecton);
    if (sortDirecton === "Ascending") {
      // console.log("shipment sorter pickupDate Ascending");
      const resortedData = shipments.sort(function (shipment1, shipment2) {
        return (
          new Date(ShipDateSelector(shipment1)) -
          new Date(ShipDateSelector(shipment2))
        );
      });
      return resortedData;
    }

    if (sortDirecton === "Descending") {
      // console.log("shipment sorter pickupDate Decending");
      const resortedData = shipments.sort((shipment1, shipment2) => {
        return (
          new Date(ShipDateSelector(shipment2)) -
          new Date(ShipDateSelector(shipment1))
        );
      });
      return resortedData;
    } else {
      console.warn("Shipmentorter.js, invalid sortDirection for pickupDate");
      return [];
    }
  }
  //
  // Del Date Sort (AKA Delivery Date)
  //

  if (sortMethod === "delDate") {
    // console.log("shipment sorter pickupDate", sortDirecton);

    if (sortDirecton === "Ascending") {
      const resortedData = shipments.sort((shipment1, shipment2) => {
        // console.log("shipment sorter pickupDate appointment Ascending");
        return (
          new Date(DeliveryDateSelector(shipment1)) -
          new Date(DeliveryDateSelector(shipment2))
        );
      });
      return resortedData;
    }
    if (sortDirecton === "Descending") {
      // console.log("shipment sorter pickupDate Decending");
      const resortedData = shipments.sort((shipment1, shipment2) => {
        return (
          new Date(DeliveryDateSelector(shipment2)) -
          new Date(DeliveryDateSelector(shipment1))
        );
      });
      return resortedData;
    } else {
      console.warn("Shipmentorter.js, invalid sortDirection for delDate");
      return [];
    }
  }
};

export default ShipmentSorter;
