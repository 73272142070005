export async function UploadDocument(formData, timeOutValue) {
  let controller = new AbortController();
  let signal = controller.signal;
  let url = "https://www2.shippersedge.com/public/carrier/uploadFile.cfm?wsdl";
  let request = new Request(url, {
    method: "POST",
    signal,
    body: formData,
  });
  setTimeout(() => controller.abort(), timeOutValue);

  let fetchResponse = await fetch(request)
    .then((response) => {
      // console.log("UploadNew response", response);
      if (!response.ok) {
        console.error("UploadNew error", response.statusText, response);
        throw Error(response.statusText);
      }
      // console.log("UploadNew fetch response", response);

      return response.text();
    })
    .then((data) => {
      // console.log("UploadFile UploadNew data", data);
      return data;
    })
    .catch((err) => {
      console.error("UploadFile UploadNew failed", err);
      if ((err = 'DOMException: "The operation was aborted. "')) {
        return "Aborted";
      } else return "UploadFile UploadNew Fetch Error Unknown Reason";
    });

  return fetchResponse;
}
