import React from "react";
import Collection from "../Collection/Collection";

const Body = (props) => {
  // For Debug:
  // useEffect(() => {
  //   console.log("Body.js saw props.shipments change");
  // }, [props.shipments]);

  if (props.portalMode === "Loading") {
    return <span>Loading....</span>;
  }

  if (props.portalMode === "SearchResults") {
    return (
      <>
        <Collection
          key={props.selectedCollection.name}
          // collection={item}
          shipments={props.searchResults}
          GetSingleShipmentRequestDashboard={
            props.GetSingleShipmentRequestDashboard
          }
        />
      </>
    );
  }

  if (
    props.shipments &&
    props.portalMode === "Collections" &&
    props.shipments.length
  ) {
    return (
      <>
        <Collection
          key={props.selectedCollection.name}
          portalType={props.portalType}
          // collection={item}
          shipments={props.shipments}
          GetSingleShipmentRequestDashboard={
            props.GetSingleShipmentRequestDashboard
          }
          sortChanged={props.sortChanged}
        />
      </>
    );
  } else {
    return null;
  }
};

export default Body;
