import React from "react";
import YesNoButtons from "../../../SmallComponents/YesNoButtons/YesNoButtons";
import useRenderStatus from "../../../../Services/Hooks/useRenderStatus";

const DeliveredQ001 = (props) => {
  const render = useRenderStatus('DeliveredQ001');
  if (render) {
    return (
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-9 my-auto">Was this shipment delivered?</div>
            <div className="col">
              <YesNoButtons setYesNo={props.setDeliveredQ001} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default DeliveredQ001;
