import React, { useState, useEffect } from "react";

const VersionChecker = () => {
  const [APIVersion, setAPIVersion] = useState("");

  //
  //  Set client version here and if it is different from the DB return it will prompt them to reload.
  //
  const clientVersion = "January 21 2021";
  //
  //
  //

  useEffect(() => {
    localStorage.setItem("version", clientVersion);
  }, []); // eslint-disable-line

  useEffect(() => {
    AsyncCall();
    const interval = setInterval(() => {
      AsyncCall();
    }, 300000);
    return () => clearInterval(interval);
  }, []); // eslint-disable-line

  const CheckPortalVersion = () => {
    return new Promise(function (resolve) {
      // console.log(
      //   "debug CheckPortalVersion.js, CheckPortalVersion()");
      let PortalName = "CustomerPortal";
      let controller = new AbortController();
      let signal = controller.signal;
      let url =
        "https://www2.shippersedge.com/public/carrier/portalVersionCheck.cfm?PortalName=" +
        PortalName +
        "&wsdl";
      let request = new Request(url, { signal });
      setTimeout(() => controller.abort(), 3600000);
      fetch(request)
        .then((response) => {
          // console.log("debug CheckPortalVersion response", response);
          if (!response.ok) {
            console.error(
              "CheckPortalVersion.js error",
              response.statusText,
              response
            );
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          // console.log("debug CheckPortalVersion data", data);
          resolve(data.ver);
        })
        .catch((err) => {
          console.error("CheckPortalVersion Request failed", err);
          resolve("");
        });
    });
  };

  const AsyncCall = async () => {
    const version = await CheckPortalVersion();
    setAPIVersion(version);
  };

  function RefreshPage() {
    window.location.reload();
  }

  if (APIVersion === "") {
    return null;
  } else {
    if (clientVersion === APIVersion) {
      return null;
    }
    if (clientVersion !== APIVersion) {
      return (
        <div className="row mb-3">
          <div className="col">
            <span
              className="TextLink"
              style={{ color: "red" }}
              onClick={RefreshPage}
            >
              Click to update to the latest version of the Customer Portal!
            </span>
          </div>
        </div>
      );
    }
  }
};

export default VersionChecker;
