import React from "react";

const Search = (props) => {
  const onEnterKeyPressStartSearch = (event) => {
    if (event.which === 13) {
      props.searchMachine();
    } else {
      return null;
    }
  };

  return (
    <div className="input-group input-group-sm ">
      <select
        className="form-control form-control-sm mr-3"
        as="select"
        name="currentSearchItem"
        onChange={(event) => props.setsearchType(event.target.value)}
      >
        <option defaultValue="refNumber" value="refNumber">
          Reference Number
        </option>
        <option value="shipDate">Ship Date</option>
        <option value="delDate">Delivery Date</option>
        <option value="delDateRange">Delivery Date Range</option>
        <option value="carrierName">Carrier Name</option>
        <option value="shipperName">Ship Name</option>
        <option value="consName">Dest Name</option>

      </select>

      {props.searchType === "refNumber" ||
        props.searchType === "carrierName" ||
        props.searchType === "shipperName" ||
        props.searchType === "consName" ? (
          <>
            <input
              type="text"
              className="form-control"
              aria-label="Search Value"
              aria-describedby="button-addon4"
              value={props.searchValue}
              onChange={(event) => props.setSearchValue(event.target.value)}
              onKeyPress={(event) => onEnterKeyPressStartSearch(event)}
            />

            <div className="input-group-append" id="button-addon4">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => props.searchMachine()}
              >
                Search
            </button>
            </div>
          </>
        ) : null}

      {props.searchType === "shipDate" || props.searchType === "delDate" ? (
        <>
          <input
            className="form-control form-control-sm"
            // disabled={!this.props.searchEnable}
            type="date"
            placeholder=""
            name="searchVal"
            value={props.searchValue}
            onChange={(event) => props.setSearchValue(event.target.value)}
            onKeyPress={(event) => onEnterKeyPressStartSearch(event)}
          />
          <div className="input-group-append" id="button-addon4">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => props.searchMachine()}
            >
              Search
            </button>
          </div>
        </>
      ) : null}

      {props.searchType === "delDateRange" ? (
        <>
          <input
            className="form-control form-control-sm"
            type="date"
            placeholder=""
            name="dateRange1"
            value={props.searchValue}
            onChange={(event) => props.setSearchValue(event.target.value)}
          // onKeyPress={(event) => onEnterKeyPressStartSearch(event)}
          />

          <input
            className="form-control form-control-sm"
            type="date"
            placeholder=""
            name="dateRange2"
            value={props.searchValue2}
            onChange={(event) => props.setSearchValue2(event.target.value)}
          // onKeyPress={(event) => onEnterKeyPressStartSearch(event)}
          />
          <div className="input-group-append" id="button-addon4">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => props.searchMachine()}
            >
              Search
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Search;
