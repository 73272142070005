import React, {useEffect, useState} from "react";
import PrimaryModal from "../../../../Components/PrimaryModal/PrimaryModal";
import ShipDate from "./Components/ShipDate";
import DeliveryDate from "./Components/DeliveryDate";
import HUPackages from "./Components/HUPackages";
import Status from "./Components/Status";
import ShipperAddress from "./Components/ShipperAddress";
import ConsAddress from "./Components/ConsAddress";
import ReferenceNumbers from "./Components/RefernceNumbers";
import CarrierName from "./Components/CarrierName";
import Costs from "./Components/Costs";
import DateFormatter from "../../../../Services/Helpers/DateFormatter";
// import UserContext from "../../../../Services/Context/UserContext";
import Documents from "./Components/Documents"

const Card = (props) => {
  const [buttonText, setButtonText] = useState("Confirm Delivery");

  useEffect(() => {
    if (props.portalType === "USDAPortal") {
      setButtonText("View/Upload Documents");
    }
  })
  // User Conext data
  // const user = useContext(UserContext);

  
  return (
    <>
      <div className="card animated">
        {/* Card Headder */}
        <div className="card-header">
          <div className="row">
            <div className="col-lg-8 col-sm-auto">
              <ReferenceNumbers shipment={props.shipment} />
            </div>
            <div className="col-lg-4 text-right">
              <Status status={props.shipment.status} />
            </div>
          </div>
        </div>
        {/* Card Body */}
        <div className="card-body">
          <div className="card-text">
            <br />
            <div className="container">
              <div className="row">
                <div className="col-sm-4">
                  {" "}
                  <ShipDate dates={props.shipment.shipper.dates} />
                  <ShipperAddress shipperAddress={props.shipment.shipper} />
                </div>

                <div className="col-sm-4">
                  <DeliveryDate dates={props.shipment.consignee.dates} />
                  <ConsAddress consAddress={props.shipment.consignee} />
                </div>
                <div className="col-sm-4">
                  <CarrierName carrier={props.shipment.carrier} />
                  <HUPackages handlingUnits={props.shipment.handlingUnits} />
                  {props.portalType === 'USDAPortal' ? null : <Costs billing={props.shipment.billing} />}
                </div>
              </div>

              {props.portalType === 'USDAPortal' ? 
                <div className="row mt-2">
                  <div className="col-sm-4 d-flex"></div>
                  <div className="col-sm-4 d-flex"></div>
                  <div className="col-sm-4">
                    Border Crossing Date: {props.shipment.international.dates.borderCrossing ? DateFormatter(props.shipment.international.dates.borderCrossing) : "No Date Available Yet"}
                  </div>
                </div>
              : 

              <div className="row mt-2">
                <div className="col-sm-4 d-flex"></div>

                <div className="col-sm-4 d-flex"></div>
                <div className="col-sm-4">
                  <Documents documents={props.shipment.docs}/>
                </div>
              </div>
              }
            </div>

            <div className="row">
              <div className="col-sm">
                <br />
                <PrimaryModal
                  portalType={props.portalType}
                  shipment={props.shipment}
                  btnTxt={buttonText}
                  GetSingleShipmentRequestDashboard={
                    props.GetSingleShipmentRequestDashboard
                  }
                />
                {/* <br />
                <button onClick={() => ShippersEdgeAPIs.getStuff()}>
                  API test Button
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default Card;
