// Filtershipments takes the shipments and the filters that comes from the selected collection and applies the filter and returns the filtered shipments

import ShipDateSelector from "./ShipmentSorter/ShipDateSelector2";
import DeliveryDateSelector from "./ShipmentSorter/DeliveryDateelector";

export const FilterShipments = async (shipments, filter) => {
  //
  //  Values and functions used for filters (these are  used inside the collections)
  //
  const today = new Date(); // eslint-disable-line
  // For some reason inside the eval() it doesn't know anything about the imported ShipDateSelector and we have to reassign it here
  const ShipDate = ShipDateSelector; // eslint-disable-line
  const DelDate = DeliveryDateSelector; // eslint-disable-line

  // Note: If the API returns a date such as 2020-07-18 and 'new Date("2020-07-18")' is applied it can result in date mismatches as it will by default read that timezone as GMT, and 2020-07-18 will be converted to GMT timezone and compared to the local timezone and those can be two different days.  The solution is to either append 'T00:00:00' to have new Date() use the loal timezone, or just create a new today and compare it as a string.
  // Example:
  // console.log("1", new Date("2020-07-18T00:00:00").toLocaleDateString());
  // console.log("1", new Date("2020-07-18").toLocaleDateString());
  // Also in collections we use '.setHours(0, 0, 0, 0)' to do date comparisons to simplify day to day comparisons.
  // Example Collection:
  //   {
  //   "name": "Activity Today",
  //   "filter": "(new Date(shipment.dates.created).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) || (new Date(ShipDate(shipment) + 'T00:00:00').setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0))"
  // },

  if (shipments && filter) {
    let filteredShipments = shipments.filter((shipment) => {
      // eslint-disable-next-line
      if (eval(filter)) {
        return shipment;
      } else {
        return null;
      }
    });
    return filteredShipments;
  } else {
    console.error("FilterShipments, missing required parameters");
    return false;
  }
};

export default FilterShipments;
