import React, { useEffect } from "react";
import useRenderStatus from "../../../../Services/Hooks/useRenderStatus";

const Notes001 = (props) => {
  const render = useRenderStatus('Notes001');
  // Set notes001 value on load to reflect the last note added, just on the inital load
  useEffect(() => {
    if (props.messages.length) {
      let filterOutOtherMessages = props.messages.filter(
        (message) => message.usercode === "CustomerPortal"
      );
      if (filterOutOtherMessages.length) {
        let mostRecentDate = props.messages.reduce((a, b) => {
          return new Date(a.entered_date) > new Date(b.entered_date) ? a : b;
        });
        //   console.log("mostRecentDate", mostRecentDate);
        props.setNotes001(mostRecentDate.entryDetails);
      }
    }
  }, []); // eslint-disable-line
  if (render) {
    return (
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="notes001">Shipment Notes</label>
                <textarea
                  className="form-control"
                  id="notes001"
                  rows="3"
                  value={props.notes001}
                  onChange={(event) => props.setNotes001(event.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else { return null }
};

export default Notes001;
