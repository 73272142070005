import React from "react";

const Password = (props) => {
  return (
    <div className="input-group mb-3">
      <input
        type="password"
        onChange={(event) => props.setPassword(event.target.value)}
        onKeyPress={(event) => props.onEnterKeyPressLogIn(event)}
        className="form-control"
        placeholder="Password"
        aria-label="Password"
      />
    </div>
  );
};

export default Password;
