import React from "react";
import Helpers from "../../../../../Services/Helpers";

const HUPackages = (props) => {
  if (props.handlingUnits) {
    return (
      <div className="SmallText card animated flex-fill">
        <div className="card-body">
          {props.handlingUnits.map((hu, index) => (
            <span key={index + Helpers.QuickKey.QuickKey()}>
              {hu.items.map((item, index) => (
                <span key={index + Helpers.QuickKey.QuickKey()}>
                  {item.description}
                  <br />
                </span>
              ))}
            </span>
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default HUPackages;
