import React from "react";
import useRenderStatus from "../../../../Services/Hooks/useRenderStatus";

const UploadDocuments = (props) => {
  const render = useRenderStatus('DeliveredQ001');
  if (render) {
    return (
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col">
              <label htmlFor="selectDocType01">Select Document Type</label>
              {props.portalType === "USDAPortal" ? 
              <select
                className="form-control"
                id="selectDocType01"
                onChange={(event) => props.setFileType(event.target.value)}
              >
                
                
              <option value="Health-Certificate" defaultValue>Health Certificate</option>
              </select>
              :
              <select
                className="form-control"
                id="selectDocType01"
                onChange={(event) => props.setFileType(event.target.value)}
              >
                <option value={null} defaultValue>
                  -- Select Document Type --
              </option>
                
                <option value="Photo">Photo</option>
                <option value="Signed-BOL">BOL</option>
                <option value="Other">Other</option>
              </select>
              }
            </div>
            <div className="col">
              <label htmlFor="inputGroupFile01">Select Document </label>
              <div className="custom-file ">
                <input
                  type="file"
                  className="custom-file-input"
                  id="inputGroupFile01"
                  onChange={(event) => props.fileChangeHandler(event)}
                />
                <label className="custom-file-label" htmlFor="inputGroupFile01">
                  {props.fileName ? props.fileName : "Choose file"}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else { return null }
};

export default UploadDocuments;
