import React from "react";
import YesNoButtons from "../../../SmallComponents/YesNoButtons/YesNoButtons";
import useRenderStatus from "../../../../Services/Hooks/useRenderStatus";

const DamagedQ001 = (props) => {
  const render = useRenderStatus('DamagedQ001');
  if (render) {
    return (
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-9 my-auto">Was this shipment damaged?</div>
            <div className="col">
              <YesNoButtons setYesNo={props.setdamaged001} />
            </div>
          </div>
        </div>
      </div>
    );
  } else { return null }
};

export default DamagedQ001;
