import React from "react";
import Card from "../Card/Card";

const Collection = (props) => {
  if (props.shipments) {
    return (
      <>
        <div>
          {props.shipments ? (
            <>
              {" "}
              {props.shipments.map((shipment) => {
                return (
                  <Card
                    portalType={props.portalType}
                    key={shipment.id}
                    shipment={shipment}
                    GetSingleShipmentRequestDashboard={
                      props.GetSingleShipmentRequestDashboard
                    }
                  />
                );
              })}
            </>
          ) : null}
        </div>
      </>
    );
  } else if (props.shipments === null) {
    return (
      <>
        <br />
        <div className="text-center">Loading....</div>
      </>
    );
  } else {
    return (
      <>
        <br />
        <div className="text-center">No Shipments.</div>
      </>
    );
  }
};

export default Collection;
