import React from "react";

const SortUI = (props) => {
  if (props.portalMode === "SearchResults") {
    return null;
  } else {
    return (
      <>
        <div className="d-flex">
          <div className="form-group mt-3 mb-3 mr-auto">
            <select
              className="form-control form-control-sm"
              as="select"
              name="currentSortItem"
              onChange={(event) => props.setsortMethod(event.target.value)}
            >
              <option defaultValue="delDate" value="delDate">
                Sort By Delivery Date
              </option>
              <option value="pickupDate">Pickup Date</option>
              <option value="BOL">Sort By BOL</option>
            </select>
          </div>
          <div className="mt-3 mb-3 SmallText">
            Current Sort Order:{" "}
            {props.sortDirection === "Descending" ? (
              <span
                className="TextLink"
                onClick={() => props.setsortDirection("Ascending")}
              >
                Descending
              </span>
            ) : (
              <span
                className="TextLink"
                onClick={() => props.setsortDirection("Descending")}
              >
                Ascending
              </span>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default SortUI;
