// DeliveryDateSelector simply selects the first available date by priority for the ShipmentSorter.js to use to sort with
// This operates much like DeliveryDate.js does when display date, prioritizing what date to use
// It defaults to Jan 1 1900 if no date is available so that the shipment can sort somehow

export const DeliveryDateSelector = (shipment) => {
  if (
    shipment.consignee.dates.actualDelivery !== "" &&
    shipment.consignee.dates.actualDelivery !== "Invalid date"
  ) {
    return shipment.consignee.dates.actualDelivery;
  }

  if (
    shipment.consignee.dates.requestedDeliveryDate !== "" &&
    shipment.consignee.dates.requestedDeliveryDate !== "Invalid date"
  ) {
    return shipment.consignee.dates.requestedDeliveryDate;
  }

  if (
    shipment.consignee.dates.available1 !== "" &&
    shipment.consignee.dates.available1 !== "Invalid date"
  ) {
    return shipment.consignee.dates.available1;
  } else {
    return "1900-01-01T00:00:00.000Z";
  }
};

export default DeliveryDateSelector;
