import ShipDateSelector from "./ShipmentSorter/ShipDateSelector2";
import DeliveryDateSelector from "../../Services/Helpers/ShipmentSorter/DeliveryDateelector";

export const SearchShipments = (
  shipments,
  searchType,
  searchValue,
  searchValue2
) => {
  switch (searchType) {
    case "refNumber":
      let searchValueNoSpaces = searchValue.replace(/\s+/g, "");

      let searchResultsAllRefNumbers = shipments.filter(
        (shipment) =>
          shipment.id.toString().includes(searchValueNoSpaces) ||
          shipment.usercontrol.toString().toLowerCase().includes(searchValueNoSpaces.toLowerCase())
      );
      return searchResultsAllRefNumbers;
    case "shipDate":
      let searchResultsPickupDate = shipments.filter(
        (shipment) =>
          Date.parse(ShipDateSelector(shipment)) === Date.parse(searchValue)
      );
      return searchResultsPickupDate;
    case "delDate":
      let searchResultsDeliveryDate = shipments.filter(
        (shipment) =>
          Date.parse(DeliveryDateSelector(shipment)) === Date.parse(searchValue)
      );
      return searchResultsDeliveryDate;
    case "carrierName":
      let searchResultsCarrierName = shipments.filter((shipment) =>
        shipment.carrier.name.toString().toLowerCase().includes(searchValue.toLowerCase())
      );
      return searchResultsCarrierName;
    case "shipperName":
      let searchResultsShipperName = shipments.filter((shipment) =>
        shipment.shipper.name.toString().toLowerCase().includes(searchValue.toLowerCase())
      );
      return searchResultsShipperName;

    case "consName":
      let searchResultsConsName = shipments.filter((shipment) =>
        shipment.consignee.name.toString().toLowerCase().includes(searchValue.toLowerCase())
      );
      return searchResultsConsName;
    case "delDateRange":
      if (Date.parse(searchValue) <= Date.parse(searchValue2)) {
        let searchResultsDelDateRange = shipments.filter(
          (shipment) =>
            Date.parse(DeliveryDateSelector(shipment)) >=
            Date.parse(searchValue) &&
            Date.parse(DeliveryDateSelector(shipment)) <=
            Date.parse(searchValue2)
        );
        return searchResultsDelDateRange;
      } else {
        alert(
          "When selecting date ranges please check that the first date selected occurs before the second date."
        );
        break;
      }
    default:
      console.warn(
        "SearchShipments.js default case reached.  That shouldn't happen, but it did."
      );
      return [];
  }
};

export default SearchShipments;
